import { graphql } from "gatsby";
import React from "react";

import Layout from "../../components/Layout";
import Seo from "../../components/seo";
import { InspirationVideoPageContext } from "../../helpers/pagesContext";
import { INSPIRATIONS } from "../../helpers/route";
import VideoScene from "../../scenes/Video/Video";

export default function Inspiration(props) {
  const inspirationVideo = props.data.inspirationVideo;
  const inspirationContainer = props.data.inspirationContainer;
  const resultLastInspirationVideo =
    props.data.lastInspirationVideo.edges.filter(
      (element) => element.node.title !== "dummy",
    );

  return (
    <Layout currentRoute={INSPIRATIONS} data={props.data.contentfulMenuLabel}>
      <Seo
        title={inspirationVideo.title}
        image={inspirationVideo.thumbnail.gatsbyImageData.images.fallback.src}
        description={inspirationVideo.metaDescription}
      />
      <InspirationVideoPageContext.Provider
        value={{
          inspirationVideo,
          resultLastInspirationVideo,
          inspirationContainer,
        }}
      >
        <VideoScene />
      </InspirationVideoPageContext.Provider>
    </Layout>
  );
}

export const pageQuery = graphql`
  query MyQuery($url: String!, $locale: String!) {
    inspirationVideo: contentfulInspirationVideo(
      url: { eq: $url }
      node_locale: { eq: $locale }
    ) {
      node_locale
      url
      metaDescription
      id
      title
      subtitle
      thumbnail {
        gatsbyImageData(placeholder: BLURRED)
      }
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      video {
        file {
          url
        }
      }
      videoUrl
      videoImage {
        gatsbyImageData(placeholder: BLURRED)
      }
      author
      authorJob
      authorPicture {
        gatsbyImageData
      }
      creation(formatString: "DD MMM YYYY", locale: "fr")
      time
    }
    lastInspirationVideo: allContentfulInspirationVideo(
      limit: 3
      sort: { fields: creation, order: DESC }
      filter: { url: { ne: $url }, node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          url
          id
          title
          subtitle
          thumbnail {
            gatsbyImageData(layout: FIXED)
          }
          content {
            raw
          }
          video {
            file {
              url
            }
          }
          author
          creation(formatString: "YYYY-MM-DD HH:mm:ss")
          time
        }
      }
    }
    inspirationContainer: contentfulInspirationContainer(
      node_locale: { eq: $locale }
    ) {
      otherVideo
      keepExploring
      goingBack
      node_locale
    }
    contentfulMenuLabel(node_locale: { eq: $locale }) {
      adventure
      career
      cv
      identity
      innovation
      insideZenity
      inspirations
      node_locale
      recrute
      univers
      contact
      accept
      decline
      cookie
      knowMore
      formation
      ourFormation
      zac
      services
      zenityFactory
    }
  }
`;
