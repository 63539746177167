import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useContext } from "react";

import { FormattedText } from "../../../../components/FormattedText/FormattedText";
import { InspirationFooterFocus } from "../../../../components/Inspiration/components/InspirationFooter/InspirationFooterFocus";
import { InspirationVideo } from "../../../../components/Inspiration/Inspiration";
import { ModalViewer } from "../../../../components/ModalViewer/ModalViewer";
import { SlideTransition } from "../../../../components/SlideTransition/SlideTransition";
import { localeLink } from "../../../../helpers/localeLink";
import { InspirationVideoPageContext } from "../../../../helpers/pagesContext";
import { INSPIRATIONS } from "../../../../helpers/route";
import * as styles from "./Body.module.css";

export const Body = () => {
  const {
    inspirationVideo: {
      id,
      content,
      video,
      videoUrl,
      videoImage,
      author,
      authorPicture,
      authorJob,
      url,
    },
    resultLastInspirationVideo,
    inspirationContainer: { goingBack, keepExploring, otherVideo },
  } = useContext(InspirationVideoPageContext);

  return (
    <section className={styles.watermark}>
      <div className={styles.back}>
        <Link to={localeLink(INSPIRATIONS)}>
          <StaticImage
            src="../../../../resources/images/article/arrow.png"
            alt="arrow"
            width={33}
          />
          <span>{goingBack}</span>
        </Link>
      </div>
      <SlideTransition delay={0.4}>
        <div className={styles.root}>
          <div className={styles.container}>
            <div className={styles.modalViewer}>
              <ModalViewer
                image={videoImage.gatsbyImageData}
                video={video?.file.url}
                url={videoUrl}
                overlay={true}
              />
            </div>
            <FormattedText content={content} />
            <InspirationFooterFocus
              author={{ name: author, picture: authorPicture, job: authorJob }}
              id={id}
              url={url}
            />
          </div>
        </div>
      </SlideTransition>
      <div className={styles.moreMedia}>
        <p>{keepExploring}</p>
        <h3>{otherVideo}</h3>
      </div>
      <div className={styles.cardMedias}>
        {resultLastInspirationVideo.map(
          ({
            node: { url, id, author, createdAt, title, thumbnail, time },
          }) => (
            <InspirationVideo
              key={id}
              {...{
                url,
                id,
                author,
                createdAt,
                title,
                thumbnail,
                time,
                delay: 0.2,
              }}
            />
          )
        )}
      </div>
    </section>
  );
};
